.hero.hero-workshop {
    min-height: 640px;
    padding-top: 0;
    width: 100%;
    overflow: hidden;
}

.workshop-jumbo-carousel-item {
    height: 84vh;
    min-height: 640px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hero.hero-workshop .workshop-hero-fixed {
    height: 77vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#workshop-format {
    padding: 12px 0 72px 0;
}

#workshop-format .format-icon {
    font-size: 48px;
}

#workshop-format .format-description,
#workshop-format .format-description li {
    font-family: "Karla", "Segoe UI", "Ubuntu", Arial, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: white;
}

#workshop-detail .instructor {
    background: #ccc;
    padding: 10px;
    height: fit-content;
}
#workshop-detail .instructor .avatar-col {
    max-height: 220px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
#workshop-detail .instructor .avatar-container {
    width: 100%;
    padding-top: 100%;
    max-width: 200px;
    position: relative;
}
#workshop-detail .instructor .avatar {
    max-height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
