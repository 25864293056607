.footer {
    font-family: "Karla", Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 21px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.footer h1 {
    font-size: 28px;
}

.footer-logo {
    margin-top: 5px;
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.footer .footer-link,
.footer .footer-link:active,
.footer .footer-link:focus {
    text-decoration: none;
    color: white;
}

.footer .footer-link:hover {
    color: #3db1be;
}
