.hero.hero-about {
    height: 100vh;
    min-height: 640px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.clubhouse-text {
    max-width: 992px;
}

.clubhouse-central-text {
    text-align: justify;
    text-align-last: center;
}
