.navbar {
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  height: 40px;
  width: 100%;
  float: left;
  top: 0;
  left: 0;
  position: fixed !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 1s;
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.8);
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left a {
  font-size: 20px;
}

.navbar a,
.navbar a:hover,
.navbar a:active {
  font-family: Lara, "Times New Roman", serif;
  color: white;
  text-decoration: none;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right a {
  font-size: 22px;
  font-weight: 300;
}

.navbar-links-desktop a,
.navbar-links-desktop a:active,
.navbar-links-desktop a:focus {
  padding-left: 50px;
}

.navbar-links-desktop a:hover {
  color: #3db1be;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .navbar {
    height: 40px;
  }

  .navbar-left a {
    font-size: 20px;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .navbar {
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .navbar-left a {
    font-size: 28px;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .navbar {
    height: 64px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .navbar-left a {
    font-size: 32px;
  }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .navbar {
    height: 72px;
  }

  .navbar-left a {
    font-size: 36px;
  }
}

#navbar-toggle {
  width: 30px;
  height: 23px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  cursor: pointer;
}

#navbar-toggle span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #ddd;
  border-radius: 50%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#navbar-toggle span:nth-child(1) {
  top: 0;
}

#navbar-toggle span:nth-child(2) {
  top: 9px;
}

#navbar-toggle span:nth-child(3) {
  top: 18px;
}

#navbar-toggle.expand span:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
  border-radius: 5%;
  background: #555;
}

#navbar-toggle.expand span:nth-child(2) {
  opacity: 0.5;
  width: 100vw;
  left: -100vw;
  background: #555;
}

#navbar-toggle.expand span:nth-child(3) {
  top: 9px;
  -webkit-transform: rotate(-225deg);
  -moz-transform: rotate(-225deg);
  -o-transform: rotate(-225deg);
  transform: rotate(-225deg);
  border-radius: 5%;
  background: #555;
}

.navmenu {
  position: absolute;
  width: 0;
  height: 100vh;
  top: 0;
  left: 100vw;
  z-index: -1;

  /*display: none;*/
  opacity: 0;
  background: rgba(255, 255, 255, 0.9);

  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.navmenu.expand {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  left: 0;
}

@media (min-width: 992px) {
  .navmenu.expand {
    display: none;
  }
}

.navmenu a,
.navmenu a:active,
.navmenu a:focus {
  display: none;
  min-width: 60%;
  text-align: center;
  font-family: Lara, "Times New Roman", serif;
  font-weight: 600;
  color: black;
  text-decoration: none;
  font-size: 32px;
}

.navmenu.expand a {
  display: block;
}

.navmenu a:hover {
  color: #023abe;
}
