/* Custom CSS */
.body {
    margin: 0;
    font-family: Lara, "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white;
}

::-moz-selection {
    color: white;
    background: #023abe;
}
::selection {
    color: white;
    background: #023abe;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Lara, "Times New Roman", serif;
    padding-top: 20px;
    padding-bottom: 10px;
}

p,
a,
li {
    font-family: "Karla", "Segoe UI", "Ubuntu", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.btn {
    text-transform: uppercase;
    font-family: Lara, "Times New Roman", serif;
    text-shadow: none;
}

.btn.btn-color-normal,
.btn.btn-color-normal:active,
.btn.btn-color-normal:focus {
    font-weight: 800;
    padding: 16px 25px;
    border-width: 2px;
    border-radius: 0;
    background-color: #fff;
    border-color: #fff;
    transition: 0.4s ease-out;
}

.btn.btn-color-normal:hover {
    padding: 16px 25px;
    border-width: 2px;
    border-color: #fff;
    background-color: transparent;
    color: #fff;
}

.btn.btn-color-invert,
.btn.btn-color-invert:active,
.btn.btn-color-invert:focus {
    font-size: medium;
    color: #333;
    font-weight: 800;
    padding: 16px 25px;
    border-width: 2px;
    border-radius: 0;
    background-color: transparent;
    border-color: #333;
    transition: 0.4s ease-out;
}

.btn.btn-color-invert:hover {
    font-size: medium;
    padding: 16px 25px;
    border-width: 2px;
    background-color: #333;
    border-color: #333;
    color: #fff;
}

.jumbotron .jumbotron-title {
    font-size: 24px;
    max-width: 100%;
    display: -webkit-box;
    /*-webkit-line-clamp: 2;*/
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jumbotron .jumbotron-subtitle {
    font-size: 32px;
    padding-top: 20px;
    padding-bottom: 10px;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jumbotron .jumbotron-description {
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 1rem;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jumbotron .btn {
    text-shadow: none;
}

@media (min-width: 576px) {
    .jumbotron .jumbotron-title {
        font-size: 36px;
    }
    .jumbotron .jumbotron-description {
        font-size: 20px;
    }
}

@media (min-width: 768px) {
    .jumbotron .jumbotron-title {
        font-size: 42px;
    }
}

.jumbotron-divider {
    border-top: 1px solid white;
    margin-top: 32px;
    height: 16px;
}

div.jumbotron-divider {
    height: 1px;
    border-top: 1px solid white;
    margin: 16px 32%;
}

.hero {
    padding-top: 40px;
}

section {
    padding: 72px 0;
}
section.dark {
    background: black;
    color: white;
}
section.light {
    background: white;
    color: black;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .hero {
        padding-top: 48px;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .hero {
        padding-top: 64px;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .hero {
        padding-top: 72px;
    }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .hero {
        padding-top: 96px;
    }
}
