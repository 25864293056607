.hero.hero-faq {
    height: 100vh;
    min-height: 640px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.faq-item {
    padding: 8px 16px;
    border-bottom: #ccc 1px solid;
    /*background: #333333;*/
}
@media (min-width: 992px) {
    .faq-item {
        padding: 32px 48px;
    }
}
.faq-item .question {
    font-weight: 800;
    cursor: pointer;
    font-size: 24px;
}
.faq-item .answer {
    height: 0;
    color: white;
}

.faq-item .answer p {
    font-size: 0;
    margin: 0;
}

.faq-item .answer.expand {
    height: fit-content;
    opacity: 1;
    z-index: 0;
}

.faq-item .answer.expand p {
    font-size: 18px;
    text-align: justify;
}
