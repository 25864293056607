/* Lara-book */
@font-face {
    font-family: "Lara";
    font-style: normal;
    font-weight: 400;
    src: local("Lara"), local("Lara book"),
        url(../fonts/Lara/Lara-book.woff2) format("woff2");
}
/* Lara-book-italic */
@font-face {
    font-family: "Lara";
    font-style: italic;
    font-weight: 400;
    src: local("Lara"), local("Lara book italic"),
        url(../fonts/Lara/Lara-book-italic.woff2) format("woff2");
}
/* Lara-light */
@font-face {
    font-family: "Lara";
    font-style: normal;
    font-weight: 200;
    src: local("Lara"), local("Lara light"),
        url(../fonts/Lara/Lara-light.woff2) format("woff2");
}
/* Lara-light-italic */
@font-face {
    font-family: "Lara";
    font-style: italic;
    font-weight: 200;
    src: local("Lara"), local("Lara light italic"),
        url(../fonts/Lara/Lara-light-italic.woff2) format("woff2");
}
/* Lara-regular */
@font-face {
    font-family: "Lara";
    font-style: normal;
    font-weight: 400;
    src: local("Lara"), local("Lara regular"),
        url(../fonts/Lara/Lara-regular.woff2) format("woff2");
}
/* Lara-regular-italic */
@font-face {
    font-family: "Lara";
    font-style: italic;
    font-weight: 400;
    src: local("Lara"), local("Lara book"),
        url(../fonts/Lara/Lara-regular-italic.woff2) format("woff2");
}

/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(../fonts/Karla/Karla-italic-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Karla";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../fonts/Karla/Karla-regular-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
