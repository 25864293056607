.hero.hero-home {
  height: 86vh;
  min-height: 480px;
  background-size: cover;
  background-position: center;
}

.home-featured-item {
  cursor: pointer;
  height: 240px;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.4s ease-out;
}

.home-featured-item:hover {
  opacity: 0.7;
}

.home-featured-item-sold-out {
  top: 32px;
  font-weight: 800;
  padding: 3px 20px 3px 10px;
  position: relative;
  width: fit-content;
  max-width: 192px;
  background-color: #ea3c43;
  color: white;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.home-featured-item-category {
  top: 32px;
  font-weight: 800;
  padding: 3px 20px 3px 10px;
  position: relative;
  width: fit-content;
  max-width: 192px;
  background-color: black;
  color: white;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.home-featured-item-title {
  /*max-width: 256px;*/
  font-size: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.home-featured-item-instructor {
  font-style: italic;
}

section#featured {
  padding: 36px 0;
}
